import { Button, Group, Stack } from "@mantine/core";
import { TbSend } from "react-icons/tb";
import { useMutation } from "@tanstack/react-query";
import ApiError from "../../interfaces/apiError";
import { onError, useApi } from "../../context/api.context";
import { INote } from "../../models/note.model";
import {
	NoteFormProvider, NoteInitialValues,
	NoteValidation,
	useNoteForm
} from "../../context/forms/note.form";
import { NoteForm } from "../forms/noteForm";
import { ISurvey } from "../../models/survey.model";

export interface ICreateNoteButtonProps {
	survey?: ISurvey;

	onCreate(): Promise<void>;

	onClose(): void;
}

export function CreateNoteForm(props: ICreateNoteButtonProps) {
	const api = useApi();

	const form = useNoteForm({
		initialValues: NoteInitialValues,
		validate: NoteValidation,
		transformValues: (values) => ({...values, surveyId: props.survey?.id!})
	});

	const createMutation = useMutation<INote, ApiError, INote>(
		["notes.Create"],
		(values) => api.notes.Create(values),
		{onError}
	);

	async function create(values: INote) {
		await createMutation.mutateAsync(values);
		await props.onCreate();
	}

	return (
		<NoteFormProvider form={form}>
			<form
				onSubmit={form.onSubmit(
					(values) => create(values),
					(errors, values) =>
						console.log(errors, values)
				)}
			>
				<Stack>
					<NoteForm loading={createMutation.isLoading}/>
					<Group position={"right"}>
						<Button
							variant={"subtle"}
							color={"gray.0"}
							disabled={createMutation.isLoading}
							onClick={props.onClose}
						>
							Cancel
						</Button>
						<Button
							type={"submit"}
							color={"grape"}
							leftIcon={<TbSend size={18}/>}
							loading={createMutation.isLoading}
						>
							Create note
						</Button>
					</Group>
				</Stack>
			</form>
		</NoteFormProvider>
	);
}