import React, { PropsWithChildren, ReactNode } from "react";
import { ActionIcon, ActionIconProps, Button, Group, MantineColor, Modal, Stack, Text, Tooltip } from "@mantine/core";
import { useState } from "react";
import { TbCheck } from "react-icons/tb";
import { ButtonProps } from "@mantine/core";

export interface IConfirmButtonProps {
	title?: ReactNode;
	message?: ReactNode;
	tooltip?: ReactNode;
	disabled?: boolean;
	buttonProps?: Omit<ButtonProps, "onClick" | "disabled">;
	okButtonProps?: Omit<ButtonProps, "onClick" | "loading">;
	cancelButtonProps?: Omit<ButtonProps, "onClick" | "disabled" | "loading">;
	variant?: "button" | "icon";
	color?: MantineColor;
	loading?: boolean;
	size?: string | number;

	onClick?(): boolean;

	onConfirm(): Promise<void>;

	onCancel?(): void;
}

export function ConfirmButton(props: PropsWithChildren<IConfirmButtonProps>): JSX.Element {
	const [open, setOpen] = useState(false);
	let {size, variant, color, buttonProps} = props;
	if (!variant) variant = "button";
	if (!color) color = "blue";

	if (!buttonProps || !buttonProps.color) {
		buttonProps = {...props.buttonProps, color};
	}

	async function onConfirm(): Promise<void> {
		await props.onConfirm();
		onClose();
	}

	function onCancel(): void {
		if (props.onCancel)
			props.onCancel();
		onClose();
	}

	function onClose(): void {
		setOpen(false);
	}

	function onClick(): void {
		if (props.onClick) {
			if (props.onClick()) setOpen(true);
		} else {
			setOpen(true);
		}
	}

	return (
		<>
			<Tooltip
				label={props.tooltip}
				disabled={!props.tooltip}
				multiline
				maw={400}
				sx={{whiteSpace: "pre-wrap"}}
				events={{touch: true, hover: true, focus: true}}
			>
				<span
					style={{
						cursor: props.disabled ? "not-allowed" : undefined,
						width: buttonProps.fullWidth ? "100%" : undefined
					}}
				>
					{
						variant === "button" &&
						<Button
							{...buttonProps}
							onClick={onClick}
							disabled={props.disabled}
							loading={props.loading}
						>
							{props.title}
						</Button>
					}
					{
						variant === "icon" &&
						<ActionIcon
							{...buttonProps as ActionIconProps}
							onClick={onClick}
							disabled={props.disabled}
							loading={props.loading}
						>
							{buttonProps?.children}
						</ActionIcon>
					}
				</span>
			</Tooltip>
			<Modal
				onClose={onClose}
				opened={open}
				centered
				title={props.title ?? "Confirm"}
				overlayProps={{
					opacity: 0.5,
					blur: 4,
					zIndex: 300
				}}
				zIndex={301}
				closeOnClickOutside={false}
				withCloseButton={false}
				closeOnEscape={false}
				radius={"lg"}
				styles={(theme) => ({
					header: {
						backgroundColor: theme.fn.themeColor(color as string, undefined, undefined, true),
						color: theme.colors.gray[0]
					},
					title: {fontSize: "1.5rem"}
				})}
				size={size}
			>
				<Stack spacing={0}>
					{
						props.children
							? props.children
							: <Text
								sx={{whiteSpace: "pre-wrap"}}
								p={"md"}
							>
								{props.message ?? "Are you sure you want to continue?"}
							</Text>
					}
					<Group position={"right"}>
						<Button
							variant={"subtle"}
							color={"gray"}
							{...props.cancelButtonProps}
							onClick={onCancel}
							disabled={props.loading}
						>
							{props.cancelButtonProps?.children || "Cancel"}
						</Button>
						<Button
							leftIcon={<TbCheck/>}
							color={color}
							{...props.okButtonProps}
							onClick={onConfirm}
							loading={props.loading}
						>
							{props.okButtonProps?.children || "OK"}
						</Button>
					</Group>
				</Stack>
			</Modal>
		</>
	);
}