import { CheckInResponse } from "./surveyStatus.enum";

export enum CheckInTypeLabels {
	Work = "Work",
	PhysicalHealth = "Physical Health",
	MentalHealth = "Mental Health",
	Money = "Money",
	Relationships = "Relationships",
}

export interface ICheckInResponse {
	guid: string;
	response1?: CheckInResponse;
	response2?: CheckInResponse;
	response3?: CheckInResponse;
	response4?: CheckInResponse;
	response5?: CheckInResponse;
}