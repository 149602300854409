import { MantineColor, useMantineTheme } from "@mantine/core";
import { CheckInTypeLabels } from "../models/checkInResponse.model";
import { TbCash, TbHeart, TbShovel, TbStethoscope } from "react-icons/tb";
import { RiMentalHealthLine } from "react-icons/ri";
import { INote } from "../models/note.model";
import { ISurvey } from "../models/survey.model";
import { useMemo } from "react";
import { INetworkPartner } from "../models/networkPartner.model";

export function useColorStrings(shade?: number) {
	const theme = useMantineTheme();
	const colorShade = shade ?? 7;

	return useMemo(
		() => [
			theme.colors.blue[colorShade],
			theme.colors.pink[colorShade],
			theme.colors.yellow[colorShade],
			theme.colors.orange[colorShade],
			theme.colors.violet[colorShade],
			theme.colors.green[colorShade],
			theme.colors.indigo[colorShade],
			theme.colors.grape[colorShade],
			theme.colors.lime[colorShade],
			theme.colors.red[colorShade],
			theme.colors.teal[colorShade],
		],
		[theme.colors, colorShade],
	);
}

export interface IColorInitials {
	initials: string;
	color: MantineColor;
}

export function useColorInitials(name?: string): IColorInitials {
	const theme = useMantineTheme();
	const colors = Object.keys(theme.colors);
	const initialsArray = name ? name.split(" ").map(i => i[0] ?? " ") : ["?"];
	const value = initialsArray.reduce((prev, curr) => prev + curr.charCodeAt(0), 0);
	const colorIndex = value % Object.keys(theme.colors).length;
	const initials = initialsArray.join("");

	return {
		initials,
		color: colors[colorIndex],
	};
}

export function getCategoryIcon(prop: keyof ISurvey | keyof INote | keyof INetworkPartner, size: number = 24) {
	switch (prop) {
		case "response1":
		case "referral1":
		case "category1":
			return <TbShovel size={size}/>;
		case "response2":
		case "referral2":
		case "category2":
			return <TbStethoscope size={size}/>;
		case "response3":
		case "referral3":
		case "category3":
			return <RiMentalHealthLine size={size}/>;
		case "response4":
		case "referral4":
		case "category4":
			return <TbCash size={size}/>;
		case "response5":
		case "referral5":
		case "category5":
			return <TbHeart size={size}/>;
	}
}

export function getCategoryLabel(prop: keyof ISurvey | keyof INote | keyof INetworkPartner) {
	switch (prop) {
		case "response1":
		case "referral1":
		case "category1":
			return CheckInTypeLabels.Work;
		case "response2":
		case "referral2":
		case "category2":
			return CheckInTypeLabels.PhysicalHealth;
		case "response3":
		case "referral3":
		case "category3":
			return CheckInTypeLabels.MentalHealth;
		case "response4":
		case "referral4":
		case "category4":
			return CheckInTypeLabels.Money;
		case "response5":
		case "referral5":
		case "category5":
			return CheckInTypeLabels.Relationships;
	}
}