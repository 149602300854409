export enum SurveyStatus {
	Pending = "Pending",
	Viewed = "Viewed",
	Submitted = "Submitted",
	Completed = "Completed",
}

export enum CheckInResponse {
	NoThanks,
	Connect
}