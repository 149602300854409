import { ISurvey } from "../../models/survey.model";
import { Box, Group, Text, Tooltip } from "@mantine/core";
import { ResponseCategoryProps } from "../../helpers/helpers";
import { getCategoryIcon, getCategoryLabel } from "../../hooks/helpers.hooks";
import { CheckInResponse } from "../../models/surveyStatus.enum";

export interface IResponseIconsProps {
	survey?: ISurvey;
}

export function ResponseIcons(props: IResponseIconsProps) {
	if (!props.survey) return null;

	return (
		<Group noWrap>
			{
				ResponseCategoryProps.map((key, index) => (
					<Tooltip
						key={index}
						label={getCategoryLabel(key)}
						openDelay={500}
						disabled={props.survey![key as keyof ISurvey] !== CheckInResponse.Connect}
					>
						<Box style={{display: "flex"}} w={24}>
							{
								props.survey![key as keyof ISurvey] === CheckInResponse.Connect
									? getCategoryIcon(key)
									: <Text align={"center"} w={"100%"}>-</Text>
							}
						</Box>
					</Tooltip>
				))
			}
		</Group>
	);
}