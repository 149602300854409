import { Group, Paper, Stack, Text } from "@mantine/core";
import { INote } from "../../models/note.model";
import { GetFormattedDate } from "../../helpers/helpers";
import { AdminUserAvatar } from "../userAvatar/userAvatar";
import { ISurvey } from "../../models/survey.model";

export interface INoteProps {
	survey?: ISurvey;
	note: INote;
}

export function Note(props: INoteProps) {
	return (
		<Stack maw={600}>
			<Paper
				withBorder
				radius={"lg"}
				p={"md"}
			>
				<Stack spacing={"xs"}>
					<Group position={"apart"} noWrap>
						<Group>
							<AdminUserAvatar showName userId={props.note.createdBy}/>
							<Text
								size={"sm"}
								color={"dimmed"}
								truncate
							>
								{GetFormattedDate(props.note.createdDate, "P p")}
							</Text>
						</Group>
					</Group>

					<Text p={"md"} sx={{whiteSpace:"pre-wrap"}}>
						{props.note.text}
					</Text>
				</Stack>
			</Paper>
		</Stack>
	);
}