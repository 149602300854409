import { createFormContext, isNotEmpty } from "@mantine/form";
import { FormRulesRecord } from "@mantine/form/lib/types";
import { INote } from "../../models/note.model";

export const NoteInitialValues: INote = {
	text: "",
	surveyId: -1
}

export const NoteValidation: FormRulesRecord<INote> = ({
	text: isNotEmpty("Please enter some notes")
});

export const [
	NoteFormProvider,
	useNoteFormContext,
	useNoteForm
] = createFormContext<INote>();