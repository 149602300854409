import { Badge, MantineColor, MantineSize } from "@mantine/core";
import { SurveyStatus } from "../../models/surveyStatus.enum";

export interface ISurveyStatusBadgeProps {
	status?: SurveyStatus;
	size?: MantineSize;
}

export function SurveyStatusBadge(props: ISurveyStatusBadgeProps) {
	let color: MantineColor;

	switch (props.status) {
		case SurveyStatus.Pending:
			color = "blue";
			break;
		case SurveyStatus.Submitted:
			color = "yellow";
			break;
		case SurveyStatus.Completed:
			color = "green";
			break;
		default:
			color = "dimmed";
			break;
	}

	return (
		<Badge
			color={color}
			sx={{userSelect: "none"}}
			size={props.size}
		>
			{props.status}
		</Badge>
	);
}