import { Button, Group, Stack, Title } from "@mantine/core";
import { TbPlus } from "react-icons/tb";
import { useState } from "react";
import { useApi } from "../../context/api.context";
import { ISurvey } from "../../models/survey.model";
import { CreateNoteModal } from "./createNoteModal";
import { NotesHistory } from "./notesHistory";
import { PermissionCheck } from "../permissionCheck/permissionCheck";

export interface INotesProps {
	survey?: ISurvey;
}

export function Notes(props: INotesProps) {
	const api = useApi();
	const [createModalOpen, setCreateModalOpen] = useState(false);

	return (
		<Stack
			maw={600}
			w={"100%"}
		>
			<Group
				position={"apart"}
				align={"center"}
			>
				<Title
					size={"h3"}
				>
					Notes
				</Title>
				<Button
					variant={"light"}
					color={"grape"}
					leftIcon={<TbPlus size={24}/>}
					onClick={() => setCreateModalOpen(true)}
					disabled={api.fetching}
				>
					Add Note
				</Button>
			</Group>
			<NotesHistory
				survey={props.survey}
			/>
			<PermissionCheck permission={"canEditSurveys"}>
				<CreateNoteModal
					open={createModalOpen}
					onClose={() => setCreateModalOpen(false)}
					survey={props.survey}
				/>
			</PermissionCheck>
		</Stack>
	);
}