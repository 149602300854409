import { Button, Group, Loader, Select, Stack } from "@mantine/core";
import { TbDeviceFloppy } from "react-icons/tb";
import { CustomModal } from "../customModal/customModal";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiError from "../../interfaces/apiError";
import { onError, useApi } from "../../context/api.context";
import { ISurvey } from "../../models/survey.model";

export interface IAssignUserModalProps {
	surveyId?: number;
	userId?: string;
	open: boolean;

	onClose(): void;
}

export function AssignUserModal(props: IAssignUserModalProps) {
	const queryClient = useQueryClient();
	const api = useApi();
	const [selectedUserId, setSelectedUserId] = useState(props.userId ?? null);

	const assignMutation = useMutation<ISurvey, ApiError>(
		["surveys.Assign"],
		() => api.surveys.Assign(props.surveyId!, selectedUserId!),
		{onError}
	);

	async function assignUser() {
		const survey = await assignMutation.mutateAsync();
		await queryClient.invalidateQueries(["surveys.GetById"]);
		await queryClient.invalidateQueries(["surveys.GetPaged"]);
		await queryClient.invalidateQueries(["surveys.GetForEmployee"]);
		await queryClient.invalidateQueries(["surveys.GetOutstandingSupportRequests"]);
		onClose(survey.assignedUser);
	}

	function onClose(assignedUserId?: string) {
		setSelectedUserId(assignedUserId ?? null);
		props.onClose();
	}

	return (
		<CustomModal
			opened={props.open}
			onClose={() => onClose(props.userId)}
			title={"Assign User"}
			color={"gray"}
		>
			<Stack
				p={"md"}
				spacing={"xl"}
				miw={300}
			>
				{
					api.fetching &&
					<Loader/>
				}
				<Select
					withinPortal
					data={api.adminUsers.selectItems}
					value={selectedUserId}
					placeholder={"Select a user"}
					onChange={(value) => setSelectedUserId(value)}
					disabled={assignMutation.isLoading}
				/>
				<Group position={"right"}>
					<Button
						color={"green"}
						leftIcon={<TbDeviceFloppy size={18}/>}
						onClick={assignUser}
						disabled={!selectedUserId || !props.surveyId}
						loading={assignMutation.isLoading}
					>
						Assign
					</Button>
				</Group>
			</Stack>
		</CustomModal>
	);
}