import {
	MantineColor,
	Modal,
	ModalBodyProps, ModalCloseButtonProps,
	ModalContentProps,
	ModalHeaderProps, ModalOverlayProps,
	ModalTitleProps,
	Text
} from "@mantine/core";
import React, { PropsWithChildren } from "react";
import { ModalRootProps } from "@mantine/core/lib/Modal/ModalRoot/ModalRoot";

export interface ICustomModalProps extends ModalRootProps {
	color?: MantineColor;

	overlayProps?: ModalOverlayProps;
	closeButtonProps?: ModalCloseButtonProps;
	contentProps?: ModalContentProps;
	headerProps?: ModalHeaderProps;
	titleProps?: ModalTitleProps;
	bodyProps?: ModalBodyProps;
}

export function CustomModal(props: PropsWithChildren<ICustomModalProps>): JSX.Element {
	const {
		color,
		title,
		overlayProps,
		closeButtonProps,
		contentProps,
		headerProps,
		titleProps,
		bodyProps,
		...rootProps
	} = props;

	return (
		<Modal.Root
			{...rootProps}
			size={rootProps.size ?? "auto"}
			radius={rootProps.radius ?? "lg"}
			centered={rootProps.centered ?? true}
			zIndex={200}
			styles={{inner: {zIndex: 200}}}
		>
			<Modal.Overlay
				{...overlayProps}
				zIndex={overlayProps?.zIndex ?? 200}
			/>
			<Modal.Content {...contentProps}>
				<Modal.Header
					{...headerProps}
					sx={headerProps?.sx ?? ((theme) => ({
						zIndex: 1,
						backgroundColor: theme.colors[color ?? "blue"][7],
						color: theme.colors[color ?? "blue"][0]
					}))}
				>
					<Modal.Title {...titleProps}>
						<Text size={"1.5rem"}>{title}</Text>
					</Modal.Title>
					<Modal.CloseButton
						{...closeButtonProps}
						variant={closeButtonProps?.variant ?? "light"}
						color={closeButtonProps?.color ?? color}
					/>
				</Modal.Header>
				<Modal.Body {...bodyProps}>
					{props.children}
				</Modal.Body>
			</Modal.Content>
		</Modal.Root>
	);
}