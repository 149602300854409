export enum SmsStatus {
	Queued1 = "1",
	Scheduled2 = "2",
	Sent3 = "3",
	Delivered4 = "4",
	Cancelled5 = "5",
	WaitApproval6 = "6",
	Failed7 = "7",
	Failed8 = "8",
	Failed9 = "9",
	Failed10 = "10",
	Failed11 = "11",
	Failed12 = "12",
	Failed13 = "13",
	Failed14 = "14",
	Failed15 = "15",
	Failed16 = "16",
	Failed17 = "17",
	Failed18 = "18",
	Failed19 = "19",
	Failed20 = "20",
	SendSuccess = "SUCCESS",
	SendErrorMissingCredentials = "MISSING_CREDENTIALS",
	SendErrorAccountNotActivated = "ACCOUNT_NOT_ACTIVATED",
	SendErrorInvalidRecipient = "INVALID_RECIPIENT",
	SendErrorThrottled = "THROTTLED",
	SendErrorInvalidSenderId = "INVALID_SENDER_ID",
	SendErrorInsufficientCredit = "INSUFFICIENT_CREDIT",
	SendErrorInvalidCredentials = "INVALID_CREDENTIALS",
	SendErrorAlreadyExists = "ALREADY_EXISTS",
	SendErrorEmptyMessage = "EMPTY_MESSAGE",
	SendErrorTooManyRecipients = "TOO_MANY_RECIPIENTS",
	SendErrorMissingRequiredFields = "MISSING_REQUIRED_FIELDS",
	SendErrorInvalidSchedule = "INVALID_SCHEDULE",
	SendErrorNotEnoughPermissionToListId = "NOT_ENOUGH_PERMISSION_TO_LIST_ID",
	SendErrorInternalError = "INTERNAL_ERROR",
	SendErrorInvalidLang = "INVALID_LANG",
	SendErrorInvalidVoice = "INVALID_VOICE",
	SendErrorSubjectRequired = "SUBJECT_REQUIRED",
	SendErrorInvalidMediaFile = "INVALID_MEDIA_FILE",
	SendErrorSomethingIsWrong = "SOMETHING_IS_WRONG",
}

export enum SmsStatusType {
	Queued = "Queued",
	Scheduled = "Scheduled",
	Sent = "Sent",
	Delivered = "Delivered",
	Cancelled = "Cancelled",
	WaitApproval = "WaitApproval",
	Failed = "Failed"
}

export interface ISmsError {
	status: SmsStatusType;
	statusCode: number;
	text: string;
}

export const SmsError: Record<SmsStatus, ISmsError> = {
	[SmsStatus.Queued1]: {
		status: SmsStatusType.Queued,
		statusCode: 200,
		text: "Message is queued for delivery"
	},
	[SmsStatus.Scheduled2]: {
		status: SmsStatusType.Scheduled,
		statusCode: 200,
		text: "Message is scheduled for delivery"
	},
	[SmsStatus.Sent3]: {
		status: SmsStatusType.Sent,
		statusCode: 200,
		text: "Message sent to the network for delivery"
	},
	[SmsStatus.Delivered4]: {
		status: SmsStatusType.Delivered,
		statusCode: 201,
		text: "Message delivered to the handset"
	},
	[SmsStatus.Cancelled5]: {
		status: SmsStatusType.Cancelled,
		statusCode: 301,
		text: "Message is cancelled"
	},
	[SmsStatus.WaitApproval6]: {
		status: SmsStatusType.WaitApproval,
		statusCode: 200,
		text: "Message is waiting manual approval from ClickSend staff"
	},
	[SmsStatus.Failed7]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Unknown failure"
	},
	[SmsStatus.Failed8]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Unknown subscriber. Number is inactive or invalid"
	},
	[SmsStatus.Failed9]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Unidentified Subscriber. Number is invalid or network routing issue."
	},
	[SmsStatus.Failed10]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Illegal Subscriber. Rejection due to failed authentication or filtering."
	},
	[SmsStatus.Failed11]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Rejection due to subscription, handset or network not supporting SMS."
	},
	[SmsStatus.Failed12]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Absent Subscriber. Phone is out of range or switched off. Likely to have been unavailable for 12 hours or more."
	},
	[SmsStatus.Failed13]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Memory Capacity Exceeded. Rejection due to subscriber handset not having the memory capacity to recieve the message. Likely to have been in state for 12 hours or more."
	},
	[SmsStatus.Failed14]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Rejection due to recipient network error or congestion."
	},
	[SmsStatus.Failed15]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Rejected by the recipient network."
	},
	[SmsStatus.Failed16]: {
		status: SmsStatusType.Failed,
		statusCode: 300,
		text: "Temporary network error - will be retried"
	},
	[SmsStatus.Failed17]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Network timeout when delivering message"
	},
	[SmsStatus.Failed18]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Network routing error"
	},
	[SmsStatus.Failed19]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Anti-spam rejection by recipient network"
	},
	[SmsStatus.Failed20]: {
		status: SmsStatusType.Failed,
		statusCode: 301,
		text: "Message delivery receipt expired"
	},
	// Send SMS response statuses
	[SmsStatus.SendSuccess]: {
		status: SmsStatusType.Sent,
		statusCode: 200,
		text: "Message added to queue OK.",
	},
	[SmsStatus.SendErrorMissingCredentials]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Not enough information has been supplied for authentication.",
	},
	[SmsStatus.SendErrorAccountNotActivated]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Your account has not been activated.",
	},
	[SmsStatus.SendErrorInvalidRecipient]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "The destination mobile number is invalid.",
	},
	[SmsStatus.SendErrorThrottled]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Identical message body recently sent to the same recipient. Please try again in a few seconds.",
	},
	[SmsStatus.SendErrorInvalidSenderId]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Invalid Sender ID.",
	},
	[SmsStatus.SendErrorInsufficientCredit]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "You have reached the end of your message credits.",
	},
	[SmsStatus.SendErrorInvalidCredentials]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Your Username or Unique Key is incorrect.",
	},
	[SmsStatus.SendErrorAlreadyExists]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "The resource you're trying to add already exists.",
	},
	[SmsStatus.SendErrorEmptyMessage]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Message is empty.",
	},
	[SmsStatus.SendErrorTooManyRecipients]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Too many recipients.",
	},
	[SmsStatus.SendErrorMissingRequiredFields]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Some required fields are missing.",
	},
	[SmsStatus.SendErrorInvalidSchedule]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "The schedule specified is invalid. Use a unix timestamp e.g. 1429170372.",
	},
	[SmsStatus.SendErrorNotEnoughPermissionToListId]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Don't have enough privilege to access or send to a list_id.",
	},
	[SmsStatus.SendErrorInternalError]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Internal error.",
	},
	[SmsStatus.SendErrorInvalidLang]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "An invalid language option has been provided.",
	},
	[SmsStatus.SendErrorInvalidVoice]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "An invalid voice (gender) option has been provided.",
	},
	[SmsStatus.SendErrorSubjectRequired]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Usually happens when MMS Subject is empty.",
	},
	[SmsStatus.SendErrorInvalidMediaFile]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Usually MMS media file is invalid file.",
	},
	[SmsStatus.SendErrorSomethingIsWrong]: {
		status: SmsStatusType.Failed,
		statusCode: 500,
		text: "Generic Error happened.",
	},
};