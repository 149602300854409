import { ScrollArea, Stack } from "@mantine/core";
import { CustomModal } from "../customModal/customModal";
import { useQueryClient } from "@tanstack/react-query";
import { ISurvey } from "../../models/survey.model";
import { CreateNoteForm } from "./createNoteForm";

export interface ICreateNoteModalProps {
	survey?: ISurvey;
	open: boolean;

	onClose(): void;
}

export function CreateNoteModal(props: ICreateNoteModalProps) {
	const queryClient = useQueryClient();

	async function onCreate() {
		await queryClient.invalidateQueries(["notes.Get"]);
		await queryClient.invalidateQueries(["surveys.GetById"]);
		await queryClient.invalidateQueries(["surveys.GetPaged"]);
		await queryClient.invalidateQueries(["surveys.GetForEmployee"]);
		await queryClient.invalidateQueries(["surveys.GetOutstandingSupportRequests"]);
		props.onClose();
	}

	return (
		<CustomModal
			opened={props.open}
			onClose={props.onClose}
			title={"Create New Note"}
			color={"grape"}
			closeOnEscape={false}
			closeOnClickOutside={false}
			size={600}
			scrollAreaComponent={ScrollArea.Autosize}
		>
			<Stack pt={"md"}>
				<CreateNoteForm
					survey={props.survey}
					onCreate={onCreate}
					onClose={props.onClose}
				/>
			</Stack>
		</CustomModal>
	);
}