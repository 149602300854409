import { Avatar, Group, MantineColor, Tooltip } from "@mantine/core";
import { ReactNode } from "react";
import {
	TbCheck,
	TbClock,
	TbExclamationMark,
	TbGavel,
	TbHourglass,
	TbQuestionMark,
	TbSend,
	TbX
} from "react-icons/tb";
import { SmsError, SmsStatus, SmsStatusType } from "../../models/smsErrors.model";

export interface ISmsStatusBadgeProps {
	status?: SmsStatus;
	fullWidth?: boolean;
}

export function SmsStatusBadge(props: ISmsStatusBadgeProps) {
	let color: MantineColor;
	let icon: ReactNode;
	const smsStatus = SmsError[props.status!];
	if (smsStatus) {
		switch (smsStatus.status) {
			case SmsStatusType.Failed:
				color = "red";
				icon = <TbExclamationMark size={16}/>;
				break;
			case SmsStatusType.Sent:
				color = "green";
				icon = <TbSend size={16}/>;
				break;
			case SmsStatusType.Delivered:
				color = "green";
				icon = <TbCheck size={16}/>;
				break;
			case SmsStatusType.Queued:
				color = "yellow";
				icon = <TbHourglass size={16}/>;
				break;
			case SmsStatusType.Scheduled:
				color = "orange";
				icon = <TbClock size={16}/>;
				break;
			case SmsStatusType.WaitApproval:
				color = "blue";
				icon = <TbGavel size={16}/>;
				break;
			case SmsStatusType.Cancelled:
				color = "gray";
				icon = <TbX size={16}/>;
				break;
			default:
				color = "gray";
				icon = <TbQuestionMark size={16}/>;
				break;
		}
	} else {
		color = "gray";
		icon = <TbQuestionMark size={16}/>;
	}
	return (
		<Group
			position={"center"}
			w={props.fullWidth ? "100%" : undefined}
		>
			<Tooltip
				label={`${smsStatus?.status ?? "Error"} - ${smsStatus?.text ?? "unknown"}`}
				events={{focus: true, hover: true, touch: true}}
			>
				<Avatar
					radius={"xl"}
					color={color}
					variant={"light"}
					size={"sm"}
				>
					{icon}
				</Avatar>
			</Tooltip>
		</Group>
	);
}