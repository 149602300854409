import { Center, Loader, Stack, Text } from "@mantine/core";
import { useQuery } from "@tanstack/react-query";
import ApiError from "../../interfaces/apiError";
import { useApi } from "../../context/api.context";
import { ISurvey } from "../../models/survey.model";
import { INote } from "../../models/note.model";
import { Note } from "./note";

export interface INotesHistoryProps {
	survey?: ISurvey;
}

export function NotesHistory(props: INotesHistoryProps) {
	const api = useApi();

	const notesQuery = useQuery<INote[], ApiError>(
		["notes.Get", api.company],
		() => api.notes.Get(props.survey?.id!),
		{
			placeholderData: [],
			enabled: !!api.company && !!props.survey?.id
		}
	);

	return (
		<Stack>
			{
				notesQuery.isFetching || notesQuery.isLoading &&
				<Center w={"100%"} p={"md"}>
					<Loader/>
				</Center>
			}
			{
				notesQuery.data?.length === 0 &&
				<Text
					align={"center"}
					color={"dimmed"}
					p={"md"}
				>
					No notes
				</Text>
			}
			{
				!notesQuery.isLoading && !notesQuery.isFetching &&
				notesQuery.data?.map(i =>
					<Note
						key={i.id}
						note={i}
						survey={props.survey}
					/>
				)
			}
		</Stack>
	);
}