import { Stack, Textarea } from "@mantine/core";
import { useNoteFormContext } from "../../context/forms/note.form";

export interface INoteFormProps {
	loading: boolean;
}

export function NoteForm(props: INoteFormProps) {
	const form = useNoteFormContext();

	return (
		<Stack>
			<Textarea
				required
				label={"Notes"}
				minRows={5}
				autoComplete={"off"}
				{...form.getInputProps("text")}
				disabled={props.loading}
			/>
		</Stack>
	);
}