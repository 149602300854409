import { TbCheck, TbMessageCircle } from "react-icons/tb";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ApiError from "../../interfaces/apiError";
import { onError, useApi } from "../../context/api.context";
import { ISurvey } from "../../models/survey.model";
import { ConfirmButton } from "../confirmButton/confirmButton";
import { SurveyStatus } from "../../models/surveyStatus.enum";
import { Button, Group, MantineSize, Paper, Stack, Text } from "@mantine/core";
import { ReferralCategoryProps } from "../../helpers/helpers";
import { getCategoryIcon, getCategoryLabel } from "../../hooks/helpers.hooks";
import { useState } from "react";
import { useApp } from "../../context/app.context";

export interface ICompleteSurveyProps {
	survey?: ISurvey;
	variant: "button" | "icon";
	size?: MantineSize;
}

export interface IReferralCategoryButtonProps {
	id: keyof ISurvey;
}

export function CompleteSurveyButton(props: ICompleteSurveyProps) {
	const queryClient = useQueryClient();
	const api = useApi();
	const app = useApp();
	const [selectedReferrals, setSelectedReferrals] = useState<string[]>([]);
	const [none, setNone] = useState(false);

	const completeMutation = useMutation<ISurvey, ApiError>(
		["surveys.Complete"],
		() => api.surveys.Complete(props.survey?.id!, selectedReferrals),
		{ onError },
	);

	async function completeSurvey() {
		await completeMutation.mutateAsync();
		await queryClient.invalidateQueries(["surveys.GetById"]);
		await queryClient.invalidateQueries(["surveys.GetPaged"]);
		await queryClient.invalidateQueries(["surveys.GetForEmployee"]);
		await queryClient.invalidateQueries(["surveys.GetOutstandingSupportRequests"]);
	}

	function onSelected(value: string) {
		if (value === "none") {
			setNone(!none);
			setSelectedReferrals([]);
			return;
		}
		const index = selectedReferrals.findIndex(i => i === value);
		if (index >= 0) {
			selectedReferrals.splice(index, 1);
		} else {
			selectedReferrals.push(value);
		}
		setSelectedReferrals([...selectedReferrals]);
		setNone(false);
	}

	function ReferralCategoryButton(props: IReferralCategoryButtonProps) {
		return (
			<Paper
				p={"md"}
				sx={{ width: 128 }}
			>
				<Stack align={"center"}>
					<Button
						variant={"light"}
						h={84}
						w={84}
						color={selectedReferrals.includes(props.id) ? "cyan" : "gray"}
						onClick={() => onSelected(props.id)}
						disabled={completeMutation.isLoading}
					>
						{getCategoryIcon(props.id, 84)}
					</Button>
					<Text align={"center"}>{getCategoryLabel(props.id)}</Text>
				</Stack>
			</Paper>
		);
	}

	if (!props.survey || props.survey.status === SurveyStatus.Completed) {
		return null;
	}

	return (
		<ConfirmButton
			title={"Complete Survey"}
			message={"Are you sure you want to mark this survey as complete?"}
			tooltip={
				props.survey?.status === SurveyStatus.Pending
					? "Survey not yet submitted"
					: !props.survey.assignedUser
						? "Please assign a user"
						: (!props.survey.noteCount || props.survey.noteCount < 1)
							? "Please add at least 1 note"
							: "Mark this survey as complete"
			}
			color={"green"}
			variant={props.variant ?? "icon"}
			buttonProps={{
				variant: props.variant === "button" ? "light" : "filled",
				radius: props.variant === "button" ? "md" : undefined,
				size: props.size ?? "lg",
				children: props.variant === "icon" ? <TbCheck size={18}/> : undefined,
				leftIcon: props.variant === "button" ? <TbCheck size={18}/> : undefined,
				fullWidth: props.variant === "button" ? true : undefined,
			}}
			loading={api.fetching || completeMutation.isLoading}
			onConfirm={completeSurvey}
			disabled={
				!app.permissions.canEditSurveys ||
				props.survey?.status !== SurveyStatus.Submitted ||
				!props.survey.assignedUser ||
				(!props.survey.noteCount || props.survey?.noteCount < 1)
			}
			size={1000}
			okButtonProps={{
				disabled: selectedReferrals.length === 0 && !none,
			}}
		>
			<Stack p={"md"}>
				<Text align={"center"}>Please select which categories this employee was referred for (or None) and press
					OK to complete this survey</Text>
				<Group
					position={"center"}
					align={"start"}
					spacing={0}
				>
					{
						ReferralCategoryProps.map((key) => (
							<ReferralCategoryButton
								key={key}
								id={key}
							/>
						))
					}
					<Paper
						p={"md"}
						sx={{ width: 128 }}
					>
						<Stack align={"center"}>
							<Button
								variant={"light"}
								h={84}
								w={84}
								color={none ? "cyan" : "gray"}
								onClick={() => onSelected("none")}
								disabled={completeMutation.isLoading}
							>
								<TbMessageCircle size={84}/>
							</Button>
							<Text align={"center"}>None</Text>
						</Stack>
					</Paper>
				</Group>
			</Stack>
		</ConfirmButton>
	);
}